import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/mobile_app_development.jpg'
import person from '../../images/person.png'

const pageTitle = "Mobile App Development";
const pageSlug = "mobile-app-development";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">By working together, we can guarantee a quick and cost-effective 
            mobile application solution on today's most used platforms.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Opportunities for mobile app development have never been greater. Most customer and business 
                    partners demand the ability to be able to interact with your business using mobile apps, websites, 
                    and the cloud.
                    </h3>
                  <p>Mobile is an essential aspect of value creation in today's complex digital landscape.</p>
                  <span className="break"></span>
                  <h4>Mobile App Development Services</h4>
                  <p>These capabilities all part of our Digital Services, we can help companies get started with 
                      digital strategy and all relevant development. Our experts understand the technology 
                      landscape and consistently deliver innovation and results.</p>
                    <p>Mobile is the fastest and most rapidly growing technology in recent history. Many organizations want 
                        to understand how new mobile capabilities can drive their business transformation. 
                        To create new opportunities for growth and innovation, as well as improving the overall 
                        customer experience.</p>
                    <p>Not sure where to get started, our team partner can help you figure that out.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h4>Service Capabilities</h4>
                    <p>We provide the means to help companies and industries define and execute their mobile strategy. 
                        Our mobile App Development practices are organized into four main areas: Mobile App Development,
                         Mobile Support and Management, and Mobile Strategy.</p>
                    <h4>Mobile App Development</h4>
                    <p>At Nuerex, our team can quickly and cost-effectively build mobile applications and web services. 
                        For all current operating systems, these include Android, IOS (iPhone & iPad).</p>
                     <h3 className="intext">'We're continuously investing and innovating in industry-specific mobile platforms that can quickly 
                         get core features done.</h3>  
                    <ul>
                        <li>Develop – We have the expertise and knowledge to build the entire mobile ecosystem, including infrastructure, 
                            system integration, web services, and disciplined project delivery methodology.</li>
                        <li>Design – We believe that the best mobile apps require a dedicated and committed team; 
                            shrinking enterprise apps won't work.</li>
                        <li>Deploy – Whether you would prefer over-the-air installation, app store implementation, or 
                            direct device configuration and deployment, we can help.</li>
                        </ul>
                       <span className="break"></span>
                       <h4>Mobile Support and Management</h4>
                       <p>Building mobile apps is only the first step in successfully deploying software. We can offer 
                           technical support and operations services.</p>
                        <ul>
                            <li>Technical Support – From testing new mobile software to introducing operating system 
                                updates, we create a reliable service level for support.</li>
                            <li>Mobile Apps Management – We know that updates, security, delivery, and reporting software 
                                are crucial to success. Since most companies are not in the mobile industry, we can assist.</li>
                        </ul>
                        <span className="break"></span>
                        <h4>Knowledge Transfer</h4>
                        <p>Some clients need a partner that can cover all their bases, while other clients want a partner to help 
                            get them started. Our experts can get your organization equipped and ready for design, development, 
                            support, or any other aspect of your mobile strategy and operations, with the following capabilities:</p>
                        <ul>
                            <li>Train – The best way to train is through experience, and our team can support and train your 
                                employees to help them feel ready.</li>
                            <li>Mentor – We can offer onsite or remote support & advice to assist your organization in 
                                understanding mobile app development and architecture.</li>
                            <li>Move- Here at Nuerex, our infrastructure experts will work with your team to seamlessly 
                                migrate everything into your company's infrastructure.</li>
                        </ul>
                        <div className="contact-box">
                        <h3>Are you interested in custom mobile solutions? Together we can create one.</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Contact us</Link>
                        </div>
                        </div>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
